import React from 'react';
import { Button, Divider } from '@mui/material';
import TinkoffButton from './Tinkoff';
import { selectLanguage } from '../../../redux/profileDataSlice';
import { useAppSelector } from '../../../utils/hooks';
import { isEmptyObject } from '../../../utils/ObjectUtils';
import splash from '../../../static/splash.png';
import logo from '../../../static/logo.png';
import styles from './Hello.module.css';

interface Interface {
  onSelectAuth: (type: string) => void;
}

const Hello = ({ onSelectAuth }: Interface) => {
  const language = useAppSelector(selectLanguage);

  return (
    <>
      <img src={splash} alt="" style={{ width: '100%', objectFit: 'contain' }} />
      <div className={styles.logo}>
        <img src={logo} alt="" />
      </div>
      {isEmptyObject(language) ? null : (
        <>
          <div className={styles.text}>{language['WELC_GWL']}</div>
          <div className={styles.buttons}>
            <Button onClick={() => onSelectAuth('manual')} className={styles.login}>
              {language['LOGIN_PH_NUM']}
            </Button>
            <Divider className={styles.divider} variant="middle">
              или
            </Divider>
            <TinkoffButton />
          </div>
        </>
      )}
    </>
  );
};

export default Hello;
