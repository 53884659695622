import React from 'react';
import { makeId } from '../../../../utils/TextUtils';
import styles from '../Hello.module.css';
import icon from '../../../../static/tinkoff_logo.svg';
import { Link } from '@mui/material';

const TinkoffButton = () => {
  const keycloakURL = `${process.env.REACT_APP_KEYCLOAK_HOST}${process.env.REACT_APP_KEYCLOAK_ROUTE}`;
  const REALM = process.env.REACT_APP_KEYCLOAK_REALM;
  const CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? '';
  const REDIRECT_ID = `${process.env.REACT_APP_API_HOST}/login`;
  const state = makeId(16);
  const nonce = makeId(16);
  const hash = makeId(16);
  const params = `?response_type=code&scope=openid&client_id=${CLIENT_ID}&state=${state}&nonce=${nonce}&hash=${hash}&kc_idp_hint=tinkoff-id&redirect_uri=${REDIRECT_ID}`;

  const handleClick = () => localStorage.setItem('state', state);

  return (
    <Link
      onClick={handleClick}
      href={`${keycloakURL}/realms/${REALM}/protocol/openid-connect/auth${params}`}
      classes={{ root: styles.tin_id }}>
      <img src={icon} className={styles.tin_id_icon} alt="" />
    </Link>
  );
};

export default TinkoffButton;
